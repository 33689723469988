import React , {useState, useEffect} from "react"
import {IoMdReorder, IoMdClose} from 'react-icons/io'
import { IconContext } from "react-icons/lib"
import logo from '../../images/logo.png'
import {animateScroll as Scroll} from 'react-scroll'
import { Link } from "gatsby"
import styled from "styled-components"



const Header = () => {

  const [click, setClick] = useState(false)
  const [scroll, setScroll] = useState(false)

  const handleClick = () => setClick(!click)


  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScroll(true)
    } else {
      setScroll(false)
    }
  }

  useEffect(() => {
    changeNav()
    window.addEventListener("scroll", changeNav)
  }, [])


  const toggleHome = () => {
    Scroll.scrollToTop();
  }


  return (
    <>

     <IconContext.Provider value={{ color: "#141414" }}>
       <Nav  active={scroll} click={click}> 
      <NavbarContainer >
        
            <NavLogo to="/" onClick={toggleHome}>
            <img src={logo} width={100}
  height={40} alt="Logo" />
            </NavLogo>
            <MobileIcon onClick={handleClick}>
              {click ? <IoMdClose /> : <IoMdReorder />}
            </MobileIcon>
            <NavMenu onClick={handleClick} click={click}>
          
              <NavItem>
                <NavLinks to="/quemsomos"> About Us </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/servicos"> Services </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/contactos">Contacts</NavLinks>
              </NavItem>        
            </NavMenu>
            </NavbarContainer>
      </Nav> 
      </IconContext.Provider>
   
    </>
  )
}


export default Header;



const Nav = styled.nav`
  background: #fff;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 10;
 
`

const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px; 
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`

const NavLogo = styled(Link)`
  justify-self: flex-start;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 20px;
`


const MobileIcon = styled.div`
  display: none;
  @media screen and (max-width: 960px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`

const NavMenu = styled.ul`
display: flex;
align-items: center;
  list-style: none;
  text-align: center;
  margin-right: 0px;

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    top: ${({ click }) => (click ? "100%" : "-1000px")};
    opacity: 1;
    transition: all 0.2s ease;
    background: #fff;
  }
`

const NavItem = styled.li`
  height: 80px;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 960px) {
    width: 100%;
  }
`


const NavLinks = styled(Link)`
  color: #163461;
  display: flex;
  align-items: center;
  font-family: "Commissioner", sans-serif;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-family: "Ubuntu", sans-serif;

  &:hover {
    border-bottom: none;
    transition: all 0.2s ease-out;
    color: #4f79b8;
  }
  @media screen and (max-width: 960px) {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    &:hover {
      color: #4f79b8;
      transition: all 0.3s ease;
    }
  }
`