import React from 'react'
import './Footer.css'
import {Link} from 'gatsby'
import { IoLogoLinkedin} from "react-icons/io"
import logo from '../../images/logo.png'
import {animateScroll as Scroll} from 'react-scroll'


function Footer() {

  const toggleHome = () => {
    Scroll.scrollToTop();
  } 

  return (
<>
    <div className='footer-container'>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
          <Link 
              to='/' onClick={toggleHome}
              ><img src={logo} width={200}
  height={80} alt="Logo" className='image' /></Link>
            <a 
              href='https://www.linkedin.com/in/s3lf-self-conscious-self-improvement-self-sufficient-473b16208/' 
              target="_blank" rel="noopener noreferrer" >
              <IoLogoLinkedin className='linkedin'/> </a>
          </div>
          <div class='footer-link-items'>
            
          <h2>ABOUT</h2>
            <Link to='/quemsomos'>About us</Link>
            <Link to='/servicos'>Services</Link>
            <Link to='/contactos'>Contacts</Link>
            
          </div>
        </div>
        
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <small class='website-rights'> © 2020 S3LF - Self Conscious, Self Improvement, Self Sufficient</small>
        </div>
      </section>
    </div>
    </>
  );
}

export default Footer;